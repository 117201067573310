import React from 'react';
import { Header, Container } from 'semantic-ui-react';
import InternalLink from '../../atoms/InternalLink';
import OutboundLink from '../../atoms/OutboundLink'
import LinkContent from './LinkContent';
import '../mobile.css';

export default ({
  slug,
  pageTitle,
  internal,
  linksAs,
  onToggle,
  centerMobileNavItems,
  linkComponent,
  backgroundColor,
  internalLinkComponent,
  className,
  disableToggle
}) => {
  const linkColor = backgroundColor === 'primary' || backgroundColor === 'secondary' ? 'white' : 'dark grey';
  const linkStyle = { color: linkColor, marginTop: '0.4em', display: 'block' };

  return (
    <Container
      {...!disableToggle ? {onClick: () => onToggle()} : {}}
      className="mobile-menu-item"
      style={{
        textAlign: centerMobileNavItems ? 'center' : 'left',
        display: 'block',
      }}
      activeStyle={{ textDecoration: 'bold' }}
    >
      {internal ? (
        <InternalLink
          gatsbyLink
          as={internalLinkComponent}
          to={slug}
          label={pageTitle}
          style={linkStyle}
          className={className}
        >
          {linksAs === 'text' ? <span>{pageTitle}</span> : <Header>{pageTitle}</Header>}
        </InternalLink>
      ) : (
        <OutboundLink label={pageTitle} to={slug} style={linkStyle} className={className}>
          {linksAs === 'text' ? <span>{pageTitle}</span> : <Header>{pageTitle}</Header>}
        </OutboundLink>
      )}
    </Container>
  );
};

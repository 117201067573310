import React from 'react';

export default class Toggleable extends React.Component {
  constructor() {
    super();
    this.state = { toggle: false };
  }

  onToggle() {
    this.setState(({ toggle }) => ({ toggle: !toggle }));
  }

  render() {
    const { toggle } = this.state;
    const { children } = this.props;

    return children(toggle, () => this.onToggle());
  }
}

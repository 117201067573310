import React from 'react';

import { Menu } from 'semantic-ui-react';
import InternalLink from '../../atoms/InternalLink';
import LinkContent from './LinkContent';
import OutboundLink from '../../atoms/InternalLink';

export default ({ linksAs, item, index, internalLinkComponent }) => {
  const { pageTitle, slug, gatsbyLink = true, internal, className } = item;

  return (
    <Menu.Item key={index}>
      {internal ? (
        <InternalLink
          gatsbyLink={gatsbyLink}
          to={slug}
          label={pageTitle}
          as={internalLinkComponent}
          className={className}
        >
          <LinkContent linksAs={linksAs} item={item} />
        </InternalLink>
      ) : (
        <OutboundLink label={pageTitle} to={slug} className={className}>
          <LinkContent linksAs={linksAs} item={item} />
        </OutboundLink>
      )}
    </Menu.Item>
  );
};

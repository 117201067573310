import { addDays, addMinutes, format as formatFunction, isValid, parse, parseISO } from 'date-fns';
import { utcToZonedTime, getTimezoneOffset, toDate } from 'date-fns-tz';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';

const DateUtils = {
  format(date, format) {
    if (format) {
      return formatFunction(date, format);
    }
    return date;
  },

  parse(dateString, format, { referenceDate = new Date(), timezone } = {}) {
    const date = parse(dateString, format, referenceDate);
    if (!timezone) {
      return date;
    }
    return utcToZonedTime(date, timezone);
  },

  /**
   *
   * @param {string} dateString: ISO datetime string in UTC (i.e: "2022-01-06T23:00:00.000Z")
   * @param {object} params: if timezone is defined, it is used to return an aware datetime
   * @returns
   */
  parseISO(dateString, timezone = undefined) {
    const date = parseISO(dateString);
    if (!timezone || !isValid(date)) {
      return date;
    }
    return toDate(date.getTime() - getTimezoneOffset(timezone, date), { timeZone: timezone });
  },

  getCurrentDate() {
    return new Date();
  },

  getTimezoneAwareNow(timezone, { format } = {}) {
    const currentDate = this.getCurrentDate();
    if (!timezone) {
      return this.format(currentDate, format);
    }
    const currentTimezoneDate = utcToZonedTime(currentDate, timezone);
    return this.format(currentTimezoneDate, format);
  },

  addDays(days, { date = new Date(), format } = {}) {
    return this.format(addDays(date, days), format);
  },

  parseHourInt(hourInt, { referenceDate = new Date() } = {}) {
    if (!hourInt && typeof hourInt !== 'number') {
      return Number.NaN;
    }
    const normalizedHourInt = hourInt === 2400 ? 0 : hourInt;
    const timeString = normalizedHourInt.toString().padStart(4, '0');
    return parse(timeString, 'HHmm', referenceDate);
  },

  getIsoDayOfWeek(date, { format } = {}) {
    // monday = 1, sunday = 7
    const dateObject = format ? parse(date, format, new Date()) : date;
    return +this.format(dateObject, 'i');
  },

  /**
   *
   * @param {string} startTime ISO datetime string
   * @param {string} endTime ISO datetime string
   * @param {string} timezone if defined, it must be a IANA valid timezone and it
   *                          will be used to parse start and end time
   * @param {function} getNow It is expected to return a Date object representing
   *                   current time. It defaults to new Date().
   * @returns true if now is between the given dates
   */
  isNowBetweenDates(startTime, endTime, timezone = null, getNow = () => new Date()) {
    const now = getNow();
    const startDate = !_isEmpty(startTime) && this.parseISO(startTime, timezone);
    const endDate = !_isEmpty(endTime) && this.parseISO(endTime, timezone);

    return (_isEmpty(startTime) || startDate < now) && (_isEmpty(endTime) || now < endDate);
  },

  intervalsBetweenHoursInt(
    startHourInt,
    finalHourInt,
    timezone = 'UTC',
    { rate = 30, format, onlyFuture = false, referenceDate = new Date() } = {},
  ) {
    const intervals = [];

    if (!_isNumber(startHourInt) || !_isNumber(finalHourInt) || !timezone) return intervals;

    const nowDate = this.getTimezoneAwareNow(timezone);
    let currentDate = this.parseHourInt(startHourInt, { referenceDate });
    const startDate = this.parseHourInt(startHourInt, { referenceDate });
    let finalDate = this.parseHourInt(finalHourInt, { referenceDate });

    if (finalDate <= startDate) {
      finalDate = addDays(finalDate, 1);
    }

    currentDate.setMinutes(0);
    while (currentDate < finalDate) {
      if (currentDate >= startDate && (currentDate > nowDate || !onlyFuture)) {
        const formattedDate = this.format(currentDate, format);
        intervals.push(formattedDate);
      }
      currentDate = addMinutes(currentDate, rate);
    }
    return intervals;
  },
};

export default DateUtils;

import React from 'react';

import { Dropdown } from 'semantic-ui-react';
import MenuItem from './MenuItem';

export default ({ title, options, linksAs, internalLinkComponent }) => {

  return (
    <Dropdown item text={title}>
      <Dropdown.Menu>
        {
          options.map((link, index) => (
            <MenuItem
              linksAs={linksAs}
              item={link}
              index={index}
              internalLinkComponent={internalLinkComponent}
              className={link.className}
            />
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};

import React from 'react';

import MenuItem from './MenuItem';
import DropdownMenu from './DropdownMenuItems';

export default ({ links, linksAs, internalLinkComponent }) => {

  return links.map((link, index) => {
    if (link.dropdown) {
      return (
        <DropdownMenu
          title={link.dropdown.title}
          options={link.dropdown.options}
          linksAs={linksAs}
          internalLinkComponent={internalLinkComponent}
        />
      );
    }

    return (
      <MenuItem
        linksAs={linksAs}
        item={link}
        index={index}
        internalLinkComponent={internalLinkComponent}
        className={link.className}
      />
    );

  });
};

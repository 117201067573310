import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

export default class OutboundLink extends React.Component {
  constructor(props) {
    super(props);
    this.sendOutboundLinkEvent = this.sendOutboundLinkEvent.bind(this);
  }

  getExtraneousProps() {
    const { to, label, eventLabel, as, newTab, ...rest } = this.props;
    return rest;
  }

  createContainerProps() {
    const { to, newTab, className, ...rest } = this.props;
    const linkProps = newTab
      ? { href: to, target: '_blank', rel: 'noopener noreferrer' }
      : { href: to };
    return { ...linkProps, ...rest, className, onClick: this.sendOutboundLinkEvent };
  }

  sendOutboundLinkEvent() {
    ReactGA.outboundLink({ label: this.createLabel() }, () => null);
  }

  createLabel() {
    const { label, eventLabel, children, to } = this.props;
    const childrenLabel = typeof children === 'string' ? children : null;
    return eventLabel || label || childrenLabel || to;
  }

  render() {
    const { label, as, children } = this.props;
    const elementProps = { ...this.createContainerProps(), children: label || children };

    return React.createElement(as, elementProps);
  }
}

OutboundLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  eventLabel: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  newTab: PropTypes.bool,
  className: PropTypes.string,
};

OutboundLink.defaultProps = {
  eventLabel: '',
  as: 'a',
  newTab: true,
  className: '',
};

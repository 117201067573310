import React from 'react';
import PropTypes from 'prop-types';

export default class InternalLink extends React.Component {
  createContainerProps() {
    const { as, to, gatsbyLink, label, className, ...rest } = this.props;

    if (gatsbyLink) return { to, className, ...rest };
    let result = to !== '' ? { href: to, className, ...rest } : { className, ...rest };
    if (as === 'a') return result;
    result = { ...result, as: 'a' };
    return result;
  }

  render() {
    const { as, label, children, className } = this.props;
    const elementProps = { ...this.createContainerProps(), children: label || children };

    return as === 'a' ? (
      <a className={className} {...this.createContainerProps()}>{elementProps.children}</a>
    ) : (
      React.createElement(as, elementProps)
    );
  }
}

InternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  gatsbyLink: PropTypes.bool,
  className: PropTypes.string
};

InternalLink.defaultProps = {
  label: '',
  as: 'a',
  gatsbyLink: false,
  className: ''
};

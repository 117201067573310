import React from 'react';

import { Dropdown } from 'semantic-ui-react';
import MobileLink from './MobileLink';

export default ({ title, options, linksAs, internalLinkComponent }) => {

  return (
    <Dropdown text={title} inline>
        <Dropdown.Menu>
            {
              options.map((link) => (
                <MobileLink
                  internalLinkComponent={internalLinkComponent}
                  centerMobileNavItems={link.centerMobileNavItems}
                  linksAs={linksAs}
                  slug={link.slug}
                  pageTitle={link.pageTitle}
                  onToggle={link.onToggle}
                  internal={link.internal}
                  linkComponent={link.as}
                  backgroundColor={link.backgroundColor}
                  className={link.className}
                  disableToggle={!!link.disableToggle}
                />
              ))
            }
        </Dropdown.Menu>
    </Dropdown>
  );
};

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import useIsClient from '../hooks/use-is-client';
import useStorage from '../hooks/use-storage';
import Storage from '../storage';
import DateUtils from '../../utils/date/DateUtils';
import MessageDisplayBanner from './components/MessageDisplayBanner';
import MessageDisplayModal from './components/MessageDisplayModal';

import './MessageDisplay.css';

const MODAL_KEY = 'showFishermanBannerModalMessage';
const BANNER_KEY = 'showFishermanBannerMessage';

export default function MessageDisplay({
  modalDelay,
  disableCache,
  businessName,
  subject,
  content,
  bannerBackgroundColor,
  modalExtra,
  modalButtonText,
  disableModalButton,
  startTime,
  endTime,
  timezone,
}) {
  const { isClient } = useIsClient();
  const [showModal, setShowModal] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [modalShownInSession, setModalShownInSession, storageLoaded] = useStorage(
    MODAL_KEY,
    false,
    {
      storageMechanism: Storage.SESSION_STORAGE,
    },
  );
  const [bannerShownInSession, setBannerShownInSession] = useStorage(BANNER_KEY, true, {
    storageMechanism: Storage.SESSION_STORAGE,
  });

  const isButtonDisabled = content ? disableModalButton : true;
  function toggleModal() {
    setShowModal((previousShowModal) => {
      if (previousShowModal && !disableCache) {
        // If we are showing the modal, make sure we don't show it again in the same session
        setModalShownInSession(true);
      }

      return !previousShowModal;
    });
  }

  useEffect(() => {
    if (modalDelay !== false && (!modalShownInSession || disableCache) && storageLoaded) {
      setTimeout(toggleModal, modalDelay);
    }
  }, [storageLoaded]);

  if (!isClient || !DateUtils.isNowBetweenDates(startTime, endTime, timezone)) {
    return null;
  }

  return (
    <div className="fm-message-display-container">
      {showBanner && bannerShownInSession && (
        <MessageDisplayBanner
          subject={subject}
          onClick={toggleModal}
          onClose={() => {
            setShowBanner(false);
            setBannerShownInSession(false);
          }}
          backgroundColor={bannerBackgroundColor}
          buttonText={modalButtonText}
          disableButton={isButtonDisabled}
        />
      )}
      {content && (
        <MessageDisplayModal
          businessName={businessName}
          open={showModal}
          subject={subject}
          content={content}
          onClose={toggleModal}
          extra={modalExtra}
        />
      )}
    </div>
  );
}

MessageDisplay.propTypes = {
  businessName: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  content: PropTypes.string,
  modalDelay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  bannerBackgroundColor: PropTypes.string,
  modalButtonText: PropTypes.string,
  modalExtra: PropTypes.element,
  disableCache: PropTypes.bool,
  disableModalButton: PropTypes.bool,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  timezone: PropTypes.string,
};

MessageDisplay.defaultProps = {
  content: null,
  modalDelay: false,
  bannerBackgroundColor: '',
  modalButtonText: 'See More',
  modalExtra: <div />,
  disableCache: false,
  disableModalButton: false,
  startTime: null,
  endTime: null,
  timezone: null,
};

import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import { globalHistory } from "@reach/router";
import  MessageDisplay  from "../MessageDisplay";
export default (props) => {
  const [showMessageDisplay, setShowMessageDisplay] = useState(true);

  useEffect(() => {
    try {
      const path = globalHistory.location.pathname;
      let arr = path.split("/");
      if (arr.length > 1 && arr[1] === "rewards") {
        setShowMessageDisplay(false);
      }
    } catch (error) {}
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query {
          allFisherman {
            edges {
              node {
                businessName
              }
            }
          }
          fishermanWebsiteLayout(
            name: { eq: "@" }
            components: { elemMatch: { fastId: { eq: "MessageDisplay" } } }
          ) {
            components {
              data
            }
          }
        }
      `}
      render={(queryData) => {
        const businessData = queryData.allFisherman.edges[0].node;
        const data = { ...businessData };

        let messageDisplayData = JSON.parse(
          queryData.fishermanWebsiteLayout.components[0].data
        )

        return (
          <>
           {showMessageDisplay && (<MessageDisplay
            subject={messageDisplayData.subject}
            content={messageDisplayData.content}
            businessName={data.businessName}
            modalDelay={messageDisplayData.modalDelay}
            bannerBackgroundColor={
              messageDisplayData.bannerBackgroundColor
            }/>)}
          </>
        )
      }}
    />
  );
};
import React from 'react';

import PropTypes from 'prop-types';
import { Button, Image } from 'semantic-ui-react';

import CloseIcon from '../assets/CloseIcon.png';

export default class MessageDisplayBanner extends React.Component {
  getContainerStyle() {
    const { backgroundColor } = this.props;

    let containerStyle = {};

    if (backgroundColor) {
      containerStyle = { ...containerStyle, backgroundColor };
    }

    return containerStyle;
  }

  render() {
    const { subject, onClick, onClose, buttonText, disableButton } = this.props;

    return (
      <div className="fm-message-display-banner" style={this.getContainerStyle()}>
        <div>
          {subject}
          {!disableButton && (
            <Button inverted size="tiny" onClick={onClick} type="button">
              {buttonText}
            </Button>
          )}
        </div>
        <Button onClick={onClose}>
          <Image src={CloseIcon} fluid  alt="close-banner"/>
        </Button>
      </div>
    );
  }
}

MessageDisplayBanner.propTypes = {
  subject: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  buttonText: PropTypes.string,
  disableButton: PropTypes.bool,
};

MessageDisplayBanner.defaultProps = {
  backgroundColor: '',
  buttonText: 'See More',
  disableButton: false,
};

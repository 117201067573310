import { useState, useEffect } from 'react';

import Storage from '../../storage';

export default function useStorage(
  key,
  defaultValue = null,
  { storageMechanism = Storage.LOCAL_STORAGE, expiry = null } = {},
) {
  const options = { storageMechanism, expiry };
  const [storageLoaded, setStorageLoaded] = useState(false);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    const valueStored = Storage.get(key, defaultValue, options);
    setValue(valueStored);
    setStorageLoaded(true);
  }, []);

  if (typeof window === 'undefined') {
    return [];
  }

  function setValueLocalStorage(newValue) {
    if (storageLoaded) {
      setValue(newValue);
      Storage.set(key, newValue, options);
    }
  }

  return [value, setValueLocalStorage, storageLoaded];
}

import React from 'react';

import PropTypes from 'prop-types';
import { Modal, Button, Image } from 'semantic-ui-react';

import Markdown from 'markdown-to-jsx';

import CloseIconDark from '../assets/CloseIconDark.png';

export default class MessageDisplayModal extends React.PureComponent {
  render() {
    const { businessName, open, subject, content, onClose, extra } = this.props;

    return (
      <Modal className="fm-message-display-modal" open={open} onClose={onClose} dimmer="inverted">
        <Modal.Header>
          <div>Message from {businessName}</div>
          <Button onClick={onClose} type="button">
            <Image src={CloseIconDark} fluid alt="close-modal"/>
          </Button>
        </Modal.Header>
        <Modal.Content scrolling>
          {subject && <h4>{subject}</h4>}
          {content && <Markdown>{content}</Markdown>}
          {extra}
        </Modal.Content>
      </Modal>
    );
  }
}

MessageDisplayModal.propTypes = {
  businessName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  subject: PropTypes.string.isRequired,
  content: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  extra: PropTypes.node,
};

MessageDisplayModal.defaultProps = {
  content: '',
  extra: null,
};

import _isNil from 'lodash/isNil';
import _isNumber from 'lodash/isNumber';
import _isObject from 'lodash/isObject';

const { parse, stringify } = JSON;

const SESSION_STORAGE = 'sessionStorage';
const LOCAL_STORAGE = 'localStorage';

function isStorageAvailable(storageName) {
  const hasWindowObj = typeof window !== 'undefined';
  return hasWindowObj && typeof window[storageName] !== 'undefined';
}

function getStorage(storageName) {
  if (isStorageAvailable(storageName)) {
    return window[storageName];
  }
  throw Error(`Storage not available or unknown: ${storageName}`);
}

const Storage = {
  SESSION_STORAGE,
  LOCAL_STORAGE,
  DEFAULT_STORAGE: LOCAL_STORAGE,

  get(key, defaultValue = null, { storageMechanism = Storage.DEFAULT_STORAGE }) {
    if (!isStorageAvailable(storageMechanism)) {
      return defaultValue;
    }

    const storedValue = getStorage(storageMechanism).getItem(key);
    if (!_isNil(storedValue)) {
      const parsedValue = parse(storedValue);
      if (!_isNil(parsedValue)) {
        if (_isObject(parsedValue) && Object.prototype.hasOwnProperty.call(parsedValue, 'expiry')) {
          if (new Date().getTime() > parsedValue.expiry) {
            getStorage(storageMechanism).removeItem(key);
            return defaultValue;
          }
          return parsedValue.value;
        }
        return parsedValue;
      }
      return null;
    }

    return defaultValue;
  },

  set(key, value, { storageMechanism = Storage.DEFAULT_STORAGE, expiry = null } = {}) {
    if (_isNil(value)) {
      return null;
    }

    let finalValue = value;
    if (expiry && _isNumber(expiry) && expiry > 0) {
      finalValue = { value, expiry: new Date().getTime() + expiry };
    }

    if (isStorageAvailable(storageMechanism)) {
      return getStorage(storageMechanism).setItem(key, stringify(finalValue));
    }

    return null;
  },

  clear(key, storageMechanism = Storage.DEFAULT_STORAGE) {
    if (isStorageAvailable(storageMechanism) && getStorage(storageMechanism).getItem(key)) {
      return getStorage(storageMechanism).removeItem(key);
    }

    return null;
  },

  destroy(storageMechanism = Storage.DEFAULT_STORAGE) {
    if (isStorageAvailable(storageMechanism)) {
      getStorage(storageMechanism).clear();
    }
  },
};

export default Storage;

import React from 'react';
import { Segment, Button, Icon } from 'semantic-ui-react';
import Toggleable from '../../Toggleable';
import MobileLink from './MobileLink';
import MobileDropdownMenu from './MobileDropddownMenuitems';

export default ({ 
  logo, 
  position, 
  links, 
  linksAs, 
  className, 
  centerMobileNavItems, 
  backgroundColor, 
  internalLinkComponent,
  invertedHamburgerButton,
}) => (
  <Segment className={className}>
    <Toggleable>
      {(toggle, onToggle) => (
        <React.Fragment>
          <div
            style={{
              textAlign: 'center',
              position: 'relative',
              verticalAlign: 'middle',
              margin: '0.5em 0',
            }}
          >
            <Button
              onClick={onToggle}
              type="button"
              style={{
                padding: 0,
                background: 'none',
                display: 'inline-block',
                float: position === 'left' ? 'right' : 'left',
                textAlign: 'center',
                height: '40px',
                width: '40px',
              }}
            >
              <div>
                <Icon
                  inverted={invertedHamburgerButton}
                  name="bars"
                  size="large"
                  color={backgroundColor === 'primary' || backgroundColor === 'secondary' ? 'primary' : 'primary'}
                  style={{ margin: '0 auto', height: 'unset' }}
                />
              </div>
            </Button>
            <div
              style={{
                margin: '0 40px',
                height: '40px',
                verticalAlign: 'middle',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {logo}
            </div>
          </div>
          {toggle && (
            <div>
              {links.map(({ slug, pageTitle, internal, as, className, disableToggle, dropdown }) => {
                if (dropdown) {
                  return (
                    <MobileDropdownMenu 
                      title={dropdown.title} 
                      options={dropdown.options} 
                      linksAs={linksAs} 
                      internalLinkComponent={internalLinkComponent} 
                    />
                  );
                }

                return (
                  <MobileLink
                    internalLinkComponent={internalLinkComponent}
                    centerMobileNavItems={centerMobileNavItems}
                    linksAs={linksAs}
                    slug={slug}
                    pageTitle={pageTitle}
                    onToggle={onToggle}
                    internal={internal}
                    linkComponent={as}
                    backgroundColor={backgroundColor}
                    className={className}
                    disableToggle={!!disableToggle}
                  />
                );

              })}
            </div>
          )}
        </React.Fragment>
      )}
    </Toggleable>
  </Segment>
);

import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Segment, Image, Header } from 'semantic-ui-react';
import ReactGA from 'react-ga';

import MobileMenu from './components/MobileMenu';
import MenuItems from './components/MenuItems';
import InternalLink from '../atoms/InternalLink';

import './mobile.css';
import './navigation.css';

function handleScroll(event) {
  const {
    srcElement: {
      documentElement: { scrollTop, scrollHeight },
    },
  } = event;
  const progress = (scrollTop / (scrollHeight - window.innerHeight)) * 100;
  const roundedProgress = Math.round(progress);
  if ([25, 50, 75, 100].includes(roundedProgress)) {
    ReactGA.event({
      category: 'Page Scroll',
      action: 'Scroll',
      label: window.location.pathname,
      value: `${roundedProgress}%`,
    });
  }
}

export default class Navigation extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', function(event) {
      handleScroll(event);
    });
  }

  componentWillUnmount() {
    window.addEventListener('scroll', function(event) {
      handleScroll(event);
    });
  }

  getWidthsProps() {
    const { links } = this.props;
    const count = this.logoHasOwnRow() ? links.length : links.length + 1;
    return this.isEvenSpacing() ? { widths: count } : {};
  }

  getContainerClassNames() {
    const { fixed, backgroundColor } = this.props;

    // create class names
    let classNames = 'navigation-container';

    if (fixed) {
      classNames += ' navigation-container-top-fixed';
    } else {
      classNames += ' navigation-container-top';
    }

    // colors
    if (backgroundColor === 'primary') {
      classNames += ' navigation-primary-background';
    } else if (backgroundColor === 'secondary') {
      classNames += ' navigation-secondary-background';
    }

    return classNames;
  }

  getContainerStyle() {
    const { backgroundColor } = this.props;

    const additionalStyle =
      backgroundColor !== 'primary' && backgroundColor !== 'none'
        ? { backgroundColor, color: 'white !important' }
        : {};

    return additionalStyle;
  }

  getPrimaryContent(mobile) {
    const { header, logo, internalLinkComponent } = this.props;
    let content = null;
    let wrapAsMenuItem = false;

    if (logo) {
      content = <Image height={mobile ? 50 : 60} src={logo} centered />;
    } else if (mobile) {
      content = (
        <Header as="h3" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          {header}
        </Header>
      );
    } else {
      content = this.logoHasOwnRow() ? (
        <Header as="h2" style={{ textAlign: 'center', marginTop: 0 }}>
          {header}
        </Header>
      ) : (
        <Header as="h3" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          {header}
        </Header>
      );
      wrapAsMenuItem = !this.logoHasOwnRow();
    }

    const primaryContent = (
      <InternalLink gatsbyLink to="/" as={internalLinkComponent}>
        {content}
      </InternalLink>
    );

    return wrapAsMenuItem ? (
      <Menu.Item header style={{ textAlign: 'center' }}>
        {primaryContent}
      </Menu.Item>
    ) : (
      primaryContent
    );
  }

  isEvenSpacing() {
    const { evenSpacing, primaryContentPosition, links, logo, header } = this.props;

    const isMiddleWithEvenLinks = primaryContentPosition === 'middle' && links.length % 2 === 0;
    const noHeaderOrLogo = header.length === 0 && logo.length === 0;

    return (
      (evenSpacing && (isMiddleWithEvenLinks || primaryContentPosition === 'top')) || noHeaderOrLogo
    );
  }

  logoHasOwnRow() {
    const { primaryContentPosition } = this.props;

    return primaryContentPosition === 'top';
  }

  renderMenuItems() {
    const {
      links,
      linksAs,
      primaryContentPosition,
      header,
      logo,
      backgroundColor,
      internalLinkComponent,
      className,
    } = this.props;

    if (primaryContentPosition === 'top' || (!header && !logo)) {
      return (
        <MenuItems
          backgroundColor={backgroundColor}
          linksAs={linksAs}
          links={links}
          internalLinkComponent={internalLinkComponent}
          className={className}
        />
      );
    }

    if (primaryContentPosition === 'middle' && links.length % 2 === 0) {
      const linksLength = links.length;
      const linksPivot = Math.floor(linksLength / 2);
      return (
        <React.Fragment>
          <MenuItems
            backgroundColor={backgroundColor}
            internalLinkComponent={internalLinkComponent}
            linksAs={linksAs}
            links={links.slice(0, linksPivot)}
            className={className}
          />
          <Menu.Item>{this.getPrimaryContent()}</Menu.Item>
          <MenuItems
            backgroundColor={backgroundColor}
            internalLinkComponent={internalLinkComponent}
            linksAs={linksAs}
            links={links.slice(linksPivot)}
            className={className}
          />
        </React.Fragment>
      );
    }

    return (
      <>
        <Menu.Item>{this.getPrimaryContent()}</Menu.Item>
        <Menu.Menu position="right" className="fisherman-navigation-right-menu">
          <MenuItems
            backgroundColor={backgroundColor}
            internalLinkComponent={internalLinkComponent}
            linksAs={linksAs}
            links={links}
          />
        </Menu.Menu>
      </>
    );
  }

  renderNavContainer(child) {
    if (this.logoHasOwnRow()) {
      return (
        <div style={this.getContainerStyle()}>
          <Segment className={this.getContainerClassNames()}>
            <Menu.Item>{this.getPrimaryContent()}</Menu.Item>
            {child}
          </Segment>
        </div>
      );
    }

    return (
      <div className={this.getContainerClassNames()} style={this.getContainerStyle()}>
        {child}
      </div>
    );
  }

  renderDesktopNav() {
    const { bordered, fixed } = this.props;

    const menuFixed = !this.logoHasOwnRow() && fixed ? 'top' : null;

    if (this.logoHasOwnRow()) {
      return this.renderNavContainer(
        <Menu
          stackable
          borderless={!bordered}
          size="massive"
          fixed={menuFixed}
          {...this.getWidthsProps()}
        >
          {this.renderMenuItems()}
        </Menu>,
      );
    }

    return this.renderNavContainer(
      <Menu
        stackable
        borderless={!bordered}
        size="massive"
        fixed={menuFixed}
        {...this.getWidthsProps()}
      >
        {this.renderMenuItems()}
      </Menu>,
    );
  }
  render() {
    const {
      linksAs,
      links,
      centerMobileNavItems,
      primaryContentPosition,
      backgroundColor,
      internalLinkComponent,
      invertedHamburgerButton,
    } = this.props;

    const desktopNav = this.renderDesktopNav();

    return (
      <React.Fragment>
        <div className="desktop-nav">{desktopNav}</div>
        <div className="mobile-nav">
          <MobileMenu
            invertedHamburgerButton={invertedHamburgerButton}
            internalLinkComponent={internalLinkComponent}
            centerMobileNavItems={centerMobileNavItems}
            logo={this.getPrimaryContent(true)}
            position={primaryContentPosition}
            links={links}
            linksAs={linksAs}
            className={this.getContainerClassNames()}
            backgroundColor={backgroundColor}
          />
        </div>
      </React.Fragment>
    );
  }
}

Navigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      pageTitle: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      internal: PropTypes.bool.isRequired,
      emphasis: PropTypes.bool,
      inverted: PropTypes.bool,
      as: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
      className: PropTypes.string,
      disableToggle: PropTypes.bool,
      dropdown: PropTypes.shape({
        title: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            pageTitle: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            internal: PropTypes.bool.isRequired,
            emphasis: PropTypes.bool,
            inverted: PropTypes.bool,
            as: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
            className: PropTypes.string,
            disableToggle: PropTypes.bool,
          }),
        ),
      }),
    }),
  ).isRequired,
  backgroundColor: PropTypes.string,
  bordered: PropTypes.bool,
  centerMobileNavItems: PropTypes.bool,
  evenSpacing: PropTypes.bool,
  fixed: PropTypes.bool,
  invertedHamburgerButton: PropTypes.bool,
  header: PropTypes.string,
  linksAs: PropTypes.oneOf(['h3', 'h4', 'h5', 'h6', 'text']),
  logo: PropTypes.string,
  internalLinkComponent: PropTypes.element,
  primaryContentPosition: PropTypes.oneOf(['top', 'left', 'middle']),
  primaryContentCentered: PropTypes.bool,
};
Navigation.defaultProps = {
  backgroundColor: 'none',
  bordered: false,
  centerMobileNavItems: true,
  evenSpacing: false,
  fixed: false,
  invertedHamburgerButton: false,
  header: '',
  linksAs: 'h4',
  logo: '',
  internalLinkComponent: 'a',
  primaryContentPosition: 'left',
  primaryContentCentered: false,
};

Navigation.propOptions = {
  backgroundColor: ['primary', 'none'],
};

Navigation.dataPropsMap = {};
